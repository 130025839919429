<template lang="pug">
    BannerGeral(:contents="contents").bannerEquipe
        ul.equipe
            li(v-for="item in contents.children")
                p
                    | {{ item.role.pt }}
                    strong {{ item.name.pt }}
</template>

<script>
import { components, props } from '@/mixins/components'

export default {
  name: 'section-banner-equipe',
  props,
  components
}
</script>

<style lang="stylus" scoped src="./BannerEquipe.styl"></style>
